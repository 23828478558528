import React from "react";
import PageTitleWrapper from "src/components/PageTitleWrapper";

import { Container, Grid, Box } from "@mui/material";
import Footer from "src/components/Footer";

export default function TableData({ Data }) {
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "6%",
          marginRight: "6%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          width={"100%"}
        >
          <Grid item xs={12} mb={6}>
            {Data}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
