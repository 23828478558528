import { Card } from "@mui/material";
import MutualFundDataTable from "./MutualFundDataTable";
import { useUserDataContext } from "src/hooks/userDataContext";
import queryString from "query-string";
import { useEffect } from "react";
import { URL_CONFIG } from "src/config/urlConfig";
import { fetchMutualFundsDataList } from "src/content/api";

function MutualFundData() {
  const { mutualFundData, updateMutualFundData, isCreate, pages } =
    useUserDataContext();

  useEffect(() => {
    const pageNo = pages;

    const fetchData = async () => {
      try {
        const newDataList = await fetchMutualFundsDataList(pageNo);
        updateMutualFundData(newDataList);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [isCreate]);

  return (
    <Card style={{ width: "100%" }}>
      <MutualFundDataTable applicants={mutualFundData?.list} />
    </Card>
  );
}

export default MutualFundData;
