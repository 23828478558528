import {
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  UserDataProvider,
  useUserDataContext,
} from "src/hooks/userDataContext";

import { Item2 } from "./CustomeItem";
import { Item, Item1 } from "./CustomeItem/index";

export const PersonalDetails = ({ view, personalData, checkDoc, ShowBtn }) => {
  const { updateApprovals } = useUserDataContext();
  const itemsData = [
    {
      label: "Aadhar Card Front Image",
      logo: "/assets/aadhaar.png",
      src: personalData?.aadharFrontImagePreview || "",
      approve: personalData?.isAadharFrontApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Aadhar Card Back Image",
      logo: "/assets/aadhaar.png",
      src: personalData?.aadharBackImagePreview || "",
      approve: personalData?.isAadharBackApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Pancard",
      logo: "/assets/pan.jpg",
      src: personalData?.panCardImagePreview || "",
      approve: personalData?.isPanCardApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Cancel Cheque",
      logo: "/assets/cheque.jpg",
      src: personalData?.cancelChequeImagePreview || "",
      approve: personalData?.isCancelChequeApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Signature",
      logo: "/assets/signature.jpg",
      src: personalData?.signatureImagePreview || "",
      approve: personalData?.isSignatureApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
  ];
  const userdata = [
    { label: "Name", value: personalData?.name || "-" },
    { label: "Email", value: personalData?.email || "-" },
    {
      label: "Place Of Birth",
      value: personalData?.placeOfBirth || "-",
    },
    { label: "Phone", value: personalData?.phone || "-" },
  ];

  const membershipType = personalData?.membershipType;

  let backgroundColor = "";
  if (membershipType == "regular") {
    backgroundColor = "#5e97d7";
  } else {
    backgroundColor = "#f7b846";
  }
  const handleApprove = async (index) => {
    updateApprovals(true);
    await checkDoc(index, true);
  };

  const handleReject = async (index) => {
    updateApprovals(true);
    await checkDoc(index, false);
  };

  return (
    <>
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"auto"}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid
          item
          xs={7.5}
          sm={7.5}
          md={7.5}
          lg={7.5}
          xl={7.5}
          sx={{ marginRight: { xs: 2.5, sm: 1, md: 2, lg: 2.5, xl: 2.5 } }}
        >
          <Item2>
            <TableContainer>
              <Box className="boxscroll">
                <Typography variant="h3" ml={1} color={"white"}>
                  Personal Details
                </Typography>
              </Box>
              <Table
                sx={{
                  minWidth: 650,
                  padding: "50px",
                  "& tbody tr:last-child td": {
                    borderBottom: 0,
                  },
                }}
                aria-label="simple table"
              >
                <TableBody
                  style={{
                    height: "auto",
                    position: "sticky",
                  }}
                >
                  {userdata?.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h4" color={"rgb(0,0,0,0.4)"}>
                          {detail.label}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4" color={"rgb(0,0,0,0.7)"}>
                          {detail.value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2}>
                      {itemsData?.map((item, index) => (
                        <Item key={index}>
                          <Avatar alt={item.label} src={item.logo} />
                          <Typography variant="h6" marginLeft={3}>
                            {item.label}
                          </Typography>
                          <Button
                            variant="outlined"
                            disabled={!item.src}
                            style={{
                              marginLeft: "auto",
                              marginRight: !item.adminapprove ? "0px" : "10px",
                            }}
                            onClick={() => {
                              view(item);
                            }}
                          >
                            View
                          </Button>

                          <>
                            {
                              <>
                                {ShowBtn === "true" && (
                                  <>
                                    {item.approve === true ? (
                                      <Box
                                        className="approvebox hoverEffect"
                                        sx={{ marginLeft: "10px" }}
                                      >
                                        <CheckCircleIcon
                                          onClick={() => handleApprove(index)}
                                          style={{
                                            color: "green",
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                            pointerEvents: "auto",
                                            opacity: 1,
                                          }}
                                        />
                                      </Box>
                                    ) : item.approve === false ? (
                                      <Box
                                        className={`approvebox ${
                                          item.src ? "hoverEffect" : ""
                                        }`}
                                        sx={{ marginLeft: "10px" }}
                                      >
                                        <CheckCircleOutlineIcon
                                          onClick={() => handleApprove(index)}
                                          style={{
                                            color: !item.src
                                              ? "#999999"
                                              : "green",
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                            pointerEvents: !item.src
                                              ? "none"
                                              : "auto",
                                            opacity: !item.src ? 0.5 : 1,
                                          }}
                                        />
                                      </Box>
                                    ) : (
                                      <></>
                                    )}

                                    {item.approve === true ? (
                                      <Box className="approvebox hoverEffect">
                                        <HighlightOffIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            color: "red",
                                            cursor: "pointer",
                                            pointerEvents: "auto",
                                            opacity: 1,
                                          }}
                                          onClick={() => handleReject(index)}
                                        />
                                      </Box>
                                    ) : item.approve === false ? (
                                      <Box
                                        className={`approvebox ${
                                          item.src ? "hoverEffect" : ""
                                        }`}
                                      >
                                        <CancelIcon
                                          style={{
                                            color: !item.src
                                              ? "#999999"
                                              : "red",
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                            pointerEvents: !item.src
                                              ? "none"
                                              : "auto",
                                            opacity: !item.src ? 0.5 : 1,
                                          }}
                                          onClick={() => handleReject(index)}
                                        />
                                      </Box>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            }
                          </>
                        </Item>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item2>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{ marginLeft: { xs: 2.5, sm: 1, md: 2, lg: 2.5, xl: 2.5 } }}
        >
          <Item1 style={{ backgroundColor, color: "black", height: "24rem" }}>
            <Typography variant="h1" gutterBottom>
              Enrollment
            </Typography>
            <Typography variant="h1" mt={2} mb={2}>
              Rs.{personalData?.membershipAmount}
            </Typography>
            <Typography
              mt={1}
              variant="h3"
              gutterBottom
              color={"rgb(0,0,0,0.8)"}
            >
              {personalData?.membershipType}
            </Typography>
          </Item1>
        </Grid>
      </Grid>
    </>
  );
};
