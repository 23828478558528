import { Typography, Button, Grid } from "@mui/material";
function PageHeader({ Title }) {
  return (
    <Grid marginLeft={8.3}>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {Title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
