import React, { createContext, useContext, ReactNode, useState } from "react";
import { useInRouterContext, useRoutes, useLocation } from "react-router";

interface UserDataContextProps {
  dashBoardData: any;
  updateDashBoard: (value: any) => void;
  userDataList: any;
  updateUserDataList: (newDataList: any) => void;
  inquiryList: any;
  updateInquiryList: (newDataList: any) => void;
  appDataList: any;
  updateAppDataList: (newAppDataList: any) => void;
  approvals: any;
  updateApprovals: (newApprovals: any) => void;
  familyApprovals: any;
  updateFamilyApprovals: (newApprovals: any) => void;
  mutualFundData: any;
  updateMutualFundData: (newMutualFundData: any) => void;
  cmsPages: any[];
  updateCmsPages: (newAppDataList: any[]) => void;
  isCreate: boolean;
  updateIsCreate: (value: any) => void;
  pages: number;
  updatePages: (value: number) => void;
  accountDetails: any;
  updateAccount: (value: any) => void;
  isSendNotification: boolean;
  updateNotification: (value: boolean) => void;
  isAddHelpPlan: boolean;
  updateHelpPlan: (value: any) => void;
  isVolunteerUploadFile: boolean;
  updateVolunteerUploadFile: (value: any) => void;
}

const UserDataContext = createContext<UserDataContextProps | undefined>(
  undefined
);

interface UserDataProviderProps {
  children: ReactNode;
}

export const UserDataProvider: React.FC<UserDataProviderProps> = ({
  children,
}) => {
  const router = useLocation();
  const [dashBoardData, setDashBoardData] = useState<any>({});
  const [userDataList, setUserDataList] = useState<any>([]);
  const [mutualFundData, setMutualFundData] = useState<any[]>();
  const [appDataList, setAppDataList] = useState<any>([]);
  const [inquiryList, setInquiryList] = useState<any>([]);
  const [approvals, setApprovals] = useState(false);
  const [familyApprovals, setFamilyApprovals] = useState({});
  const [cmsPages, setCmsPages] = useState([]);
  const [pages, setPages] = useState<number>(1);
  const [isSendNotification, SetSendNotification] = useState<any>(false);
  const [isAddHelpPlan, SetIsAddHelpPlan] = useState<any>(false);
  const [isVolunteerUploadFile, SetIsVolunteerUploadFile] =
    useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>({
    app: "false",
    user: "false",
    mutual: "false",
    profile: "false",
    patient: "false",
    amount: "false",
    notification: "false",
  });
  const [accountDetails, setAccountDetails] = useState<any>({});

  const updateDashBoard = (newDataList: any) => {
    setDashBoardData(newDataList);
  };
  const updateUserDataList = (newDataList: any) => {
    setUserDataList(newDataList);
  };
  const updateInquiryList = (newDataList: any) => {
    setInquiryList(newDataList);
  };
  const updateAppDataList = (newAppDataList: any) => {
    setAppDataList(newAppDataList);
  };
  const updateApprovals = (newApprovals) => {
    setApprovals(newApprovals);
  };
  const updateFamilyApprovals = (newApprovals) => {
    setFamilyApprovals(newApprovals);
  };
  const updateCmsPages = (updatepage) => {
    setCmsPages(updatepage);
  };
  const updateMutualFundData = (newMutualFundData) => {
    setMutualFundData(newMutualFundData);
  };
  const updateIsCreate = (newValue: {
    app?: boolean;
    user?: boolean;
    mutual?: boolean;
    profile?: boolean;
    patient?: boolean;
    amount?: boolean;
    notification?: boolean;
  }) => {
    setIsCreate((prevState) => ({
      ...prevState,
      ...newValue,
    }));
  };
  const updatePages = (value: number) => {
    setPages(value);
  };
  const updateAccount = (value: any) => {
    setAccountDetails(value);
  };
  const updateNotification = (value: boolean) => {
    SetSendNotification(value);
  };
  const updateHelpPlan = (newValue: any) => {
    SetIsAddHelpPlan((prevState) => ({
      ...prevState,
      ...newValue,
    }));
  };
  const updateVolunteerUploadFile = (value: any) => {
    SetIsVolunteerUploadFile((prevState) => ({
      ...prevState,
      ...value,
    }));
  };
  return (
    <UserDataContext.Provider
      value={{
        userDataList,
        updateUserDataList,
        appDataList,
        updateAppDataList,
        approvals,
        updateApprovals,
        cmsPages,
        updateCmsPages,
        mutualFundData,
        updateMutualFundData,
        isCreate,
        updateIsCreate,
        familyApprovals,
        updateFamilyApprovals,
        pages,
        updatePages,
        accountDetails,
        updateAccount,
        dashBoardData,
        updateDashBoard,
        updateInquiryList,
        inquiryList,
        isSendNotification,
        updateNotification,
        isAddHelpPlan,
        updateHelpPlan,
        isVolunteerUploadFile,
        updateVolunteerUploadFile,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);

  if (!context) {
    throw new Error(
      "useUserDataContext must be used within a UserDataProvider"
    );
  }

  return context;
};
