import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import NotificationDataTable from "./NotificationDataTable";
import { fetchNotificationDetails } from "src/content/api";
import { useUserDataContext } from "src/hooks/userDataContext";

function NotificationData() {
  const [notificationData, SetNotificationData] = useState<any[]>([]);
  const token = localStorage.getItem("token");
  const { isSendNotification, pages, isCreate, updatePages } =
    useUserDataContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const pageNo = pages;
      try {
        const newDataList = await fetchNotificationDetails(
          token,
          searchQuery,
          pageNo
        );
        SetNotificationData(newDataList);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [isSendNotification, isCreate, pages, searchQuery, search]);
   useEffect(() => {
     updatePages(1);
   }, []);
  const handleSearchChange = (event: any) => {
    setSearch(true);
    updatePages(1);
    setSearchQuery(event.target.value);
  };
  return (
    <Card style={{ width: "100%" }}>
      <NotificationDataTable
        notifications={notificationData}
        handleSearch={handleSearchChange}
        searchValue={searchQuery}
      />
    </Card>
  );
}

export default NotificationData;
