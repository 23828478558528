import { FC, useState, useEffect, useRef } from "react";
import {
  Divider,
  Box,
  Button,
  Card,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Input,
  TablePagination,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchUserDataList,
  uploadVounteerPdf,
  vhpUpdateFile,
} from "src/content/api";
import { useFormik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink, useNavigate } from "react-router-dom";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import * as Yup from "yup";
import { VolunteerFileType } from "../Enum";
import { useUserDataContext } from "src/hooks/userDataContext";
import { TableOverflow } from "../components/CustomeItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { URL_CONFIG } from "src/config/urlConfig";

interface VolunteerDataTableProps {
  volunteerData: any;
  handleSearch: any;
  searchValue: any;
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "380px",
      width: 250,
    },
  },
};
const validationSchema = Yup.object({
  file: Yup.mixed().required("Please upload file."),
  selectType: Yup.string().required("Select file type is required"),
  selectedUsers: Yup.array().min(1, "At least one user must be selected"),
});

const VolunteerDataTable: FC<VolunteerDataTableProps> = ({
  volunteerData,
  handleSearch,
  searchValue,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [listLength, setListLength] = useState<any>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { updateVolunteerUploadFile, pages, updatePages, updateIsCreate } =
    useUserDataContext();
  const [isDelete, setDelete] = useState(false);
  const navigate = useNavigate();
  const [isNewAdd, setIsNewAdd] = useState(false);
  const [limit] = useState<number>(10);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);

  const volunteerTypes = [
    {
      label: "Details of All Help Transactions",
      value: VolunteerFileType.HELP_TRANSACTION,
    },
    {
      value: VolunteerFileType.HELP_PLAN,
      label: "VHSP Details with latest update",
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const newDataList = await fetchUserDataList(searchQuery);
        setUserList(newDataList.list);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [searchQuery]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleCreateNew = () => {
    setOpenModal(true);
    setIsNewAdd(true);
    setSearchQuery("");
    formik.resetForm();
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setDelete(false);
    formik.resetForm();
  };

  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      file: null,
      selectType: "",
      selectedUsers: [],
    },
    validationSchema,
    onSubmit: async values => {
      try {
        if (values.selectedUsers.length === 0) {
          toast.error("Please select at least one user.");
          return;
        }

        if (!values.file) {
          toast.error("Please upload a file.");
          return;
        }

        const userIds =
          values.selectedUsers[0] === "*" ? ["*"] : values.selectedUsers;

        const payload = {
          userIds,
          pdf: values.file,
          id: values.selectType !== "help-plan" ? 1 : 2,
        };
        const response = await uploadVounteerPdf(
          token,
          payload.id,
          payload.pdf,
          payload.userIds
        );
        if (response) {
          toast.success("File uploaded successfully!");
          setOpenModal(false);
          updateVolunteerUploadFile(true);
        } else {
          toast.error("Error uploading file.");
        }
      } catch (error) {
        console.error("Error while uploading file:", error);
        toast.error("An unexpected error occurred.");
      }
    },
  });
  const handleCheckboxChange = (value: string) => {
    let newSelectedUsers = [...selectedUsers];

    if (value === "*") {
      newSelectedUsers = selectedUsers.includes("*")
        ? []
        : ["*", ...userList.map((user) => user.id)];
    } else {
      if (selectedUsers.includes(value)) {
        newSelectedUsers = newSelectedUsers.filter(
          (id) => id !== value && id !== "*"
        );
      } else {
        if (newSelectedUsers.length == listLength - 1) {
          newSelectedUsers = ["*", ...userList.map((user) => user.id)];
        } else {
          newSelectedUsers = [...newSelectedUsers, value];
        }
      }
    }

    setSelectedUsers(newSelectedUsers);
    formik.setFieldValue("selectedUsers", newSelectedUsers);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      formik.setFieldValue("file", file);
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    formik.setFieldValue("file", file);
  };

  const handleFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleCancelFile = () => {
    formik.setFieldValue("file", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSelectTypeChange = e => {
    const newSelectType = e.target.value;
    formik.setFieldValue("selectType", newSelectType);
  };
  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${URL_CONFIG.BASE_URL}/admin/api/v1/volunteer/delete-volunteer/${selectedApplicantId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        updateVolunteerUploadFile(true);
        setOpenModal(false);
        setDelete(false);
        toast.error("Delete Successfully");
      } else {
        console.error("Delete failed");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };
  const handleUpdateFile = async () => {
    try {
      const payload = {
        file: formik.values.file,
      };
      const response = await vhpUpdateFile(
        token,
        payload.file,
        selectedApplicantId
      );
      if (response) {
        updateVolunteerUploadFile(true);
        setOpenModal(false);
        toast.success("Update Successfully");
      } else {
        console.error("Update failed");
      }
    } catch (error) {
      console.error("Error during updateFile:", error);
    }
  };
  const closeModal = () => {
    setOpenModal(false);
    setDelete(false);
    updateVolunteerUploadFile(false);
  };
  const delModalOpen = applicantsid => {
    setDelete(true);
    setOpenModal(true);
    setSelectedApplicantId(applicantsid);
  };
  const handleEditClick = (applicant: any): void => {
    console.log(applicant, "applicant");
    formik.setValues({
      file: applicant.file || "",
      selectType: applicant.fileType || "",
      selectedUsers: Array.isArray(applicant.userId)
        ? applicant.userId
        : applicant.userId
        ? [applicant.userId]
        : [],
    });
    setSelectedApplicantId(applicant.volunteerId);
    setOpenModal(true);
    setIsNewAdd(false);
  };
  const handlePageChange = (event: any, newPage: number): void => {
    updatePages(newPage + 1);
    updateIsCreate({ amount: "true" });
  };
  return (
    <>
      <Card>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          p={1}
          gap={2}
        >
          <Box>
            {" "}
            <TextField
              label="Search user id"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
            />
          </Box>
          <Box
            minWidth={310}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <TablePagination
              component="div"
              count={volunteerData.total}
              onPageChange={handlePageChange}
              page={pages - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10]}
            />
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleCreateNew}
            >
              Upload File
            </Button>
          </Box>
        </Box>
        <Divider />
        <TableContainer>
          <TableOverflow searchQuery={""}>
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 5,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "15px", width: "15%", textAlign: "center" }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Sr
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "15px", textAlign: "center", width: "15%" }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      User Id
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "15px", textAlign: "center", width: "15%" }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Plan Type
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "15px", textAlign: "center", width: "15%" }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {volunteerData?.list?.map((data, index) => {
                  console.log(
                    volunteerTypes.find((type) => type.value === data.fileType),
                    data
                  );

                  return (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        <Typography variant="body1" fontWeight="bold">
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1" fontWeight="bold">
                          {data.userId == "*" ? "All" : data.userId}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1" fontWeight="bold">
                          {
                            volunteerTypes?.find(
                              (type) => type.value !== data.fileType
                            )?.label
                          }
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          fontSize={15}
                          noWrap
                          textAlign={"center"}
                          sx={{ width: "8rem" }}
                          component="div"
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              navigate(`${data.volunteerId}`);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton onClick={() => handleEditClick(data)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => delModalOpen(data?.volunteerId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableOverflow>
        </TableContainer>
      </Card>

      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          {isDelete ? (
            <Box mt={2} sx={{ textAlign: "center" }}>
              <Box>
                <Typography variant="h3">Are You Sure?</Typography>
              </Box>
              <Typography variant="body1">
                You are About to Delete this
              </Typography>
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  sx={{ mr: 1 }}
                >
                  Yes
                </Button>
                <Button variant="contained" onClick={closeModal} sx={{ ml: 3 }}>
                  No
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Typography variant="h4" gutterBottom mb={3}>
                {isNewAdd ? " Upload File" : "Update File"}
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="file-upload">
                  <Box
                    sx={{
                      mt: 3,
                      padding: "20px",
                      border: "2px dashed",
                      borderColor: formik.errors.file ? "red" : "gray",
                      textAlign: "center",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                    }}
                    onDragOver={handleFileDragOver}
                    onDrop={handleFileDrop}
                  >
                    {formik.values.file ? (
                      <Typography
                        variant="body1"
                        fontWeight={700}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        {formik.values.file.name}
                        <span
                          onClick={handleCancelFile}
                          style={{ marginRight: "5px" }}
                        >
                          <CloseIcon />
                        </span>
                      </Typography>
                    ) : (
                      <>
                        <CloudUploadIcon sx={{ fontSize: "50px" }} />
                      </>
                    )}

                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Drag and drop a file or click to browse
                    </Typography>
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-upload"
                      ref={fileInputRef}
                    />
                  </Box>
                </label>
                {formik.touched.file && formik.errors.file && (
                  <Typography color="error" variant="body2" mt={0.5}>
                    {formik.errors.file}
                  </Typography>
                )}

                {isNewAdd && (
                  <>
                    {" "}
                    <FormControl fullWidth sx={{ mt: 3 }}>
                      <InputLabel
                        sx={{
                          "&.Mui-focused": {
                            color: formik.errors.selectType ? "red" : "",
                          },
                        }}
                      >
                        Plan Type
                      </InputLabel>
                      <Select
                        value={formik.values.selectType}
                        onChange={handleSelectTypeChange}
                        label="Plan Type"
                        error={
                          formik.touched.selectType &&
                          Boolean(formik.errors.selectType)
                        }
                      >
                        {volunteerTypes.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.selectType && formik.errors.selectType && (
                        <Typography color="error" variant="body2">
                          {formik.errors.selectType}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                      <InputLabel
                        sx={{
                          "&.Mui-focused": {
                            color: formik.errors.selectedUsers ? "red" : "",
                          },
                        }}
                      >
                        Select Users
                      </InputLabel>
                      <Select
                        label="Select Users"
                        multiple
                        value={formik.values.selectedUsers}
                        onChange={(e) =>
                          formik.setFieldValue("selectedUsers", e.target.value)
                        }
                        renderValue={(selected) =>
                          selected.includes("*") ? "All" : selected.join(", ")
                        }
                        error={
                          formik.touched.selectedUsers &&
                          Boolean(formik.errors.selectedUsers)
                        }
                        MenuProps={MenuProps}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mb: 1, ml: -1 }}
                          >
                            <Checkbox
                              checked={formik.values.selectedUsers.includes(
                                "*"
                              )}
                              disabled={searchQuery.length > 0}
                              onChange={() => handleCheckboxChange("*")}
                            />
                            <ListItemText primary="Select All" />
                          </Box>
                          <TextField
                            label="Search Users"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            fullWidth
                          />
                        </Box>

                        <Box sx={{ marginTop: "15px" }}>
                          {userList.length > 0 &&
                            userList.map((user) => (
                              <MenuItem
                                key={user.id}
                                value={user.id}
                                sx={{
                                  px: 0,
                                  py: 1,
                                  ml: -1,
                                }}
                              >
                                <Checkbox
                                  checked={formik.values.selectedUsers.includes(
                                    user.id
                                  )}
                                  sx={{ ml: "3px" }}
                                  onChange={() => handleCheckboxChange(user.id)}
                                />
                                <Box display={"flex"} flexDirection={"column"}>
                                  <ListItemText
                                    primary={user.name || "-"}
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#333",
                                    }}
                                  />
                                  <ListItemText
                                    primary={user.userId}
                                    sx={{
                                      fontSize: "14px",
                                      color: "#555",
                                      ml: 0.1,
                                    }}
                                  />
                                </Box>
                                <ListItemText
                                  primary={`(${user.email})`}
                                  sx={{
                                    ml: 1,
                                    color: "#555",
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                    flexWrap: "wrap",
                                  }}
                                />
                              </MenuItem>
                            ))}
                        </Box>
                      </Select>
                      {formik.touched.selectedUsers &&
                        formik.errors.selectedUsers && (
                          <Typography color="error" variant="body2">
                            {formik.errors.selectedUsers}
                          </Typography>
                        )}
                    </FormControl>
                  </>
                )}
                <Box textAlign="right" mt={3}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      isNewAdd ? formik.handleSubmit() : handleUpdateFile()
                    }
                  >
                    {isNewAdd ? "Submit" : "Save Change"}
                  </Button>
                </Box>
              </form>
            </>
          )}
        </Box>
      </Modal>

      <ToastContainer autoClose={1000} style={{ marginTop: "70px" }} />
    </>
  );
};

export default VolunteerDataTable;
