import { FC, useState } from "react";

import PropTypes from "prop-types";

import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,

  Typography,
  CardHeader,
  IconButton,
  Modal,
  TextField,
  Button,
 
} from "@mui/material";

import { AppDataStatus } from "src/models/app";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useUserDataContext } from "src/hooks/userDataContext";
import { URL_CONFIG } from "src/config/urlConfig";
import AddIcon from "@mui/icons-material/Add";
import { AppDataProps } from "src/models/app";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
;
import { TableOverflow } from "../components/CustomeItem";
interface AppDataTableProps {
  className?: string;
  applicants: AppDataProps[];
}

interface Filters {
  status?: AppDataStatus;
}
const applyFilters = (
  applicants: AppDataProps[] | undefined,
  filters: Filters
): AppDataProps[] => {
  if (!applicants || !Array.isArray(applicants)) {
    return [];
  }

  return applicants.filter((applicant) => {
    let matches = true;
    if (filters.status && applicant.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};


const MutualFundDataTable: FC<AppDataTableProps> = ({ applicants }: any) => {
  const [page, setPage] = useState<number>(0);

  const [limit, setLimit] = useState<number>(10);
  const [openModal, setOpenModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    mutualFundData,
    updateMutualFundData,
    updateIsCreate,
    updatePages,
    pages,
  } = useUserDataContext();

  const [name, setName] = useState({
    mutualFundSchemeId: "",
    name: "",
    error: false,
    errorMessage: "",
  });

  const [isNewAdd, setIsNewAdd] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    status: null,
  });

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
    updatePages(newPage + 1);
    updateIsCreate({ mutual: "true" });
  };

  const filteredMutualFundData = applyFilters(applicants, filters);

  const handleEditClick = (applicant: any): void => {
    setName(applicant);
    setOpenModal(true);
    setIsNewAdd(false);
  };

  const handleModalClose = (): void => {
    setOpenModal(false);
    setDelete(false);
  };

  const handleSaveChanges = async (): Promise<void> => {
    try {
      if (!name) {
        return;
      }

      const response = await fetch(
        `${URL_CONFIG.BASE_URL}/admin/api/v1/mutual-funds/${name.mutualFundSchemeId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name.name }),
        }
      );
      if (response.status === 200) {
        updateMutualFundData(name?.name);
        setOpenModal(false);
        updateIsCreate({ mutual: true });
        toast.success("Save Change Successfully");
      } else if (response.status === 500) {
        setName({
          ...name,
          error: true,
          errorMessage: "Name already exists.",
        });
      } else {
        console.error("Failed to save changes. API returned:", response);
      }
    } catch (error) {
      console.error("Error while saving changes:", error);
    }
  };
  const handeCreateNew = (): void => {
    setOpenModal(true);
    setIsNewAdd(true);
    setName({
      mutualFundSchemeId: "",
      name: "",
      error: false,
      errorMessage: "",
    });
  };
  const handleNewAdd = async () => {
    try {
      if (!name || !name.name) {
        setName({
          ...name,
          error: true,
          errorMessage: "Please enter a value.",
        });
        return;
      }
      const response = await fetch(
        `${URL_CONFIG.BASE_URL}/admin/api/v1/mutual-funds/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name?.name }),
        }
      );
      if (response.status === 200) {
        const responseData = await response.json();
        setOpenModal(false);
        updateIsCreate({ mutual: true });
        toast.success("Add Successfully");
      } else if (response.status === 500) {
        setName({
          ...name,
          error: true,
          errorMessage: "Name already exists.",
        });
      } else {
        console.error("Failed to create. API returned:", response);
      }
    } catch (error) {
      console.error("Error while creating data:", error);
    }
  };

  const delModalOpen = (applicantsid) => {
    setDelete(true);
    setOpenModal(true);
    setSelectedApplicantId(applicantsid);
  };

  const closeModal = () => {
    setOpenModal(false);
    setDelete(false);
    updateIsCreate({ mutual: false });
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${URL_CONFIG.BASE_URL}/admin/api/v1/mutual-funds/${selectedApplicantId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        updateIsCreate({ mutual: true });
        setOpenModal(false);
        setDelete(false);
        toast.error("Delete Successfully");
      } else {
        console.error("Delete failed");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          action={
            <Box display='flex' alignItems='center' margin={1} mr={2}>
              <TablePagination
                component='div'
                count={mutualFundData?.total}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10]}
                sx={{ marginRight: "10px" }}
              />
              <Button
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={handeCreateNew}
              >
                Create New
              </Button>
            </Box>
          }
  
        />

        <Divider />
        <TableContainer>
          <TableOverflow searchQuery={searchQuery}>
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 5,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Sr
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Name
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "right",
                      display: "flex",
                    }}
                  >
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                        marginLeft: "auto",
                        marginRight: "40px",
                      }}
                    >
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMutualFundData.map((data, index) => (
                  <TableRow hover key={data.applicantId}>
                    <TableCell>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        gutterBottom
                        fontSize={15}
                        noWrap
                        align='center'
                      >
                        {index + 1 + page * limit}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        gutterBottom
                        noWrap
                        fontSize={15}
                        align='center'
                      >
                        {data.name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontSize: "15px",
                        textAlign: "right",
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          marginLeft: "auto",
                          marginRight: "28px",
                        }}
                      >
                        <IconButton
                          onClick={() => delModalOpen(data?.mutualFundSchemeId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEditClick(data)}>
                          <EditIcon />
                        </IconButton>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableOverflow>
        </TableContainer>
      </Card>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          {isDelete ? (
            <Box mt={2} sx={{ textAlign: "center" }}>
              <Box>
                <Typography variant='h3'>Are You Sure?</Typography>
              </Box>
              <Typography variant='body1'>
                You are About to Delete This Name
              </Typography>
              <Box mt={2} display='flex' justifyContent='center'>
                <Button
                  variant='contained'
                  color='error'
                  onClick={handleDelete}
                  sx={{ mr: 1 }}
                >
                  Yes
                </Button>
                <Button variant='contained' onClick={closeModal} sx={{ ml: 3 }}>
                  No
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Typography id='modal-modal-title' variant='h4' component='h2'>
                {isNewAdd ? "Create New" : "Edit Name"}
              </Typography>
              <TextField
                label='Name'
                variant='outlined'
                value={name?.name}
                onChange={(e) => {
                  setName({
                    ...name,
                    name: e.target.value,
                  });
                }}
                fullWidth
                error={name.error}
                helperText={name.errorMessage}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: name.error ? "red" : "",
                    },
                  },
                }}
              />
              <Box display={"flex"}>
                <Button
                  variant='contained'
                  onClick={isNewAdd ? handleNewAdd : handleSaveChanges}
                  sx={{ mt: 2, ml: "auto" }}
                >
                  {isNewAdd ? "Create" : "Save Change"}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      <ToastContainer autoClose={1000} style={{ marginTop: "70px" }} />
    </>
  );
};

MutualFundDataTable.propTypes = {
  applicants: PropTypes.array.isRequired,
};

MutualFundDataTable.defaultProps = {
  applicants: [],
};

export default MutualFundDataTable;
