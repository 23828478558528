import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useParams } from "react-router";
import { fetchVounteerDetails } from "src/content/api";
import { Download as DownloadIcon } from "@mui/icons-material";

const VolunteerDeatils = () => {
  const [vounteerData, setVounteerData] = useState<any>([]);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await fetchVounteerDetails(token, id);
        setVounteerData([data.data]);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [id]);

  const handleDownloadBill = async (url) => {
    const response = await fetch(url);

    if (!response.ok) {
      console.error("Failed to fetch file:", response.statusText);
      return;
    }

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "";
    link.click();

    URL.revokeObjectURL(link.href);
  };

  return (
    <Box p={3}>
      <Box mt={4}>
        <Card variant="outlined" sx={{ p: 3, mb: 3 }}>
          <Typography variant="h3" gutterBottom>
            Vounteer Details
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    width: "20%",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="rgba(34, 51, 84, 0.7)"
                    gutterBottom
                    noWrap
                  >
                    Sr
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="rgba(34, 51, 84, 0.7)"
                    gutterBottom
                    noWrap
                  >
                    File Name
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="rgba(34, 51, 84, 0.7)"
                    gutterBottom
                    noWrap
                  >
                    File Type
                  </Typography>
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vounteerData?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      fontSize={15}
                      textAlign={"center"}
                      noWrap
                    >
                      {index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      fontSize={15}
                      textAlign={"center"}
                      noWrap
                    >
                      {data.fileUrl.split("_").pop() || "Unknown File"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      fontSize={15}
                      textAlign={"center"}
                      noWrap
                    >
                      {data.fileType || "Not available"}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDownloadBill(data.filePreview)}
                      sx={{
                        minWidth: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        padding: 0,
                      }}
                    >
                      <DownloadIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Box>
    </Box>
  );
};

export default VolunteerDeatils;
