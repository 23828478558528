import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchPatientList } from "src/content/api";
import PatientDataTable from "./PatientDataTable";
import { useUserDataContext } from "src/hooks/userDataContext";

function PatientData() {
  const [patientData, SetPatientData] = useState<any[]>([]);
  const token = localStorage.getItem("token");
 
   const { pages, isCreate, updatePages } = useUserDataContext();
   const [searchQuery, setSearchQuery] = useState("");
   const [search, setSearch] = useState(false);
   useEffect(() => {
     const fetchData = async () => {
       const pageNo = pages;
       try {
         const newDataList = await fetchPatientList(token, searchQuery, pageNo);
         SetPatientData(newDataList?.data);
       } catch (error) {
         console.error("Error fetching data:", error.message);
       }
     };

     fetchData();
   }, [searchQuery, search, pages, isCreate]);
   useEffect(() => {
     updatePages(1);
   }, []);
   const handleSearchChange = (event: any) => {
     setSearch(true);
     updatePages(1);
     setSearchQuery(event.target.value);
   };

  return (
    <Card style={{ width: "100%" }}>
      <PatientDataTable
        patientList={patientData}
        handleSearch={handleSearchChange}
        searchValue={searchQuery}
      />
    </Card>
  );
}

export default PatientData;
