import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "../components/PageHeader";
import Footer from "src/components/Footer";
import MutualFundData from "./MutualFundsData";
import TableData from "src/components/Table";
import { Helmet } from "react-helmet-async";

export default function MutualFunds() {
  return (
    <>
      <Helmet>
       <title>Hwp Mutual Fund Data</title>
        <link rel="icon" href="/AIM HWP_SVG.svg" />
      </Helmet>
      <PageTitleWrapper>
        <PageHeader Title={"Mutual Fund Data"} />
      </PageTitleWrapper>
      <TableData Data={<MutualFundData />}></TableData>
      <Footer />
    </>
  );
}
