import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "./layouts/SidebarLayout";
import SuspenseLoader from "./components/SuspenseLoader";
import BaseLayout from "./layouts/BaseLayout";
import ViewUserDetails from "./content/applications/ViewUserDetails";
import MutualFunds from "./content/applications/MutualFunds";
import RichTextBox from "./content/applications/RichTextBox";
import Notifications from "./content/applications/Notifications";
import Patient from "./content/applications/Patient";
import PatientDetails from "./content/applications/Patient/[id]";
import Volunteer from "./content/applications/Volunteer";
import VoluntaryHelp from "./content/applications/VoluntaryHelp";
import VolunteerDeatils from "./content/applications/Volunteer/[id]/VolunteerDeatils";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Dashboards

const Crypto = Loader(lazy(() => import("./content/dashboards")));

// Applications

const UserProfile = Loader(
  lazy(() => import("./content/applications/Users/profile"))
);

const Login = Loader(lazy(() => import("./content/Pages/Login")));
const ForgotPassword = Loader(
  lazy(() => import("./content/Pages/ForgotPassword"))
);
const UserDetails = Loader(
  lazy(() => import("./content/applications/UserDetails"))
);
const AppData = Loader(lazy(() => import("./content/applications/AppDetails")));
const Inquiry = Loader(lazy(() => import("./content/applications/Inquiry")));

const ViewAppDetails = Loader(
  lazy(() => import("./content/applications/ViewAppDetails"))
);

// Components

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,

    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgotPassword",
        element: <ForgotPassword />,
      },
      {
        path: "/",
        element: <Navigate to={"/login"} />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "dashboard",
        element: <Crypto />,
      },
    ],
  },
  {
    path: "management",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="transactions" replace />,
      },

      {
        path: "userdetails",
        element: <UserDetails />,
      },
      {
        path: "appdata",
        element: <AppData />,
      },
      {
        path: "appdata/viewappdetails/:applicantId",
        element: <ViewAppDetails />,
      },
      {
        path: "userdetails/viewuserdetails/:applicantId",
        element: <ViewUserDetails />,
      },
      {
        path: "mutualfunddata",
        element: <MutualFunds />,
      },
      {
        path: "patient",
        element: <Patient />,
      },
      {
        path: "patient/:id",
        element: <PatientDetails />,
      },
      {
        path: "volunteer",
        element: <Volunteer />,
      },
      {
        path: "volunteer/:id",
        element: <VolunteerDeatils />,
      },
      {
        path: "voluntaryhelp",
        element: <VoluntaryHelp />,
      },

      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "richtextbox/:cmsPageId",
        element: <RichTextBox />,
      },
      {
        path: "inquiry",
        element: <Inquiry />,
      },

      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Navigate to="details" replace />,
          },
          {
            path: "details",
            element: <UserProfile />,
          },
        ],
      },
    ],
  },
];

export default routes;
