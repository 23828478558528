import { FC, useState, useEffect } from "react";
import {
  Divider,
  Box,
  Button,
  Card,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchUserDataList,
  vhpAddTotalAmount,
  vhpTotalAmountDelete,
  vhpUpdateTotalAmount,
} from "src/content/api";
import { VhpAmountType } from "../Enum";
import Label from "src/components/Label";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useUserDataContext } from "src/hooks/userDataContext";
import { TableOverflow } from "../components/CustomeItem";

interface VoluntaryDataTableProps {
  voluntaryData: any;
  handleSearch: any;
  searchValue: any;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "380px",
      width: 250,
    },
  },
};

const validationSchema = Yup.object({
  totalAmount: Yup.number()
    .required("Total amount is required")
    .min(0, "Total amount must be greater than or equal to 0"),
  selectType: Yup.string().required("Select type is required"),
  selectedUsers: Yup.array().min(1, "At least one user must be selected"),
});

const VoluntaryDataTable: FC<VoluntaryDataTableProps> = ({
  voluntaryData,
  handleSearch,
  searchValue,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [listLength, setListLength] = useState<any>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { updateHelpPlan, updatePages, updateIsCreate, pages } =
    useUserDataContext();
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [isDelete, setDelete] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(false);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newDataList = await fetchUserDataList(searchQuery);
        setUserList(newDataList.list);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [searchQuery]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleCreateNew = () => {
    setOpenModal(true);
    setIsNewAdd(true);
    setSearchQuery("");
    setSelectedUsers([]);
    formik.resetForm();
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setDelete(false);
    formik.resetForm();
  };

  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      totalAmount: "",
      selectType: "",
      selectedUsers: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.selectedUsers.length === 0) {
          toast.error("Please select at least one user.");
          return;
        }

        const userIds =
          values.selectedUsers[0] === "*" ? ["*"] : values.selectedUsers;

        const payload = {
          userIds,
          totalAmount: values.totalAmount,
          id: values.selectType === "USED" ? 1 : 2,
        };

        const response = await vhpAddTotalAmount(
          token,
          payload.id,
          payload.userIds,
          payload.totalAmount
        );

        if (response) {
          toast.success("Voluntary data sent successfully!");
          updateHelpPlan(true);
          setOpenModal(false);
        } else {
          toast.error("Error sending voluntary data.");
        }
      } catch (error) {
        console.error("Error while sending voluntary data:", error);
        toast.error("An unexpected error occurred.");
      }
    },
  });
  const handleUpdateAmount = async () => {
    try {
      const payload = {
        totalAmount: formik.values.totalAmount,
      };
      const response = await vhpUpdateTotalAmount(
        token,
        selectedApplicantId,
        payload.totalAmount
      );
      if (response) {
        updateHelpPlan(true);
        setOpenModal(false);
        toast.success("Update Successfully");
      } else {
        console.error("Update failed");
      }
    } catch (error) {
      console.error("Error during updateData:", error);
    }
  };
  const handleCheckboxChange = (value: string) => {
    let newSelectedUsers = [...selectedUsers];

    if (value === "*") {
      newSelectedUsers = selectedUsers.includes("*")
        ? []
        : ["*", ...userList.map((user) => user.id)];
    } else {
      if (selectedUsers.includes(value)) {
        newSelectedUsers = newSelectedUsers.filter(
          (id) => id !== value && id !== "*"
        );
      } else {
        if (newSelectedUsers.length == listLength - 1) {
          newSelectedUsers = ["*", ...userList.map((user) => user.id)];
        } else {
          newSelectedUsers = [...newSelectedUsers, value];
        }
      }
    }

    setSelectedUsers(newSelectedUsers);
    formik.setFieldValue("selectedUsers", newSelectedUsers);
  };

  const handleSelectTypeChange = (e) => {
    const newSelectType = e.target.value;
    formik.setFieldValue("selectType", newSelectType);
  };
  const handleDelete = async () => {
    try {
      const response = await vhpTotalAmountDelete(token, selectedApplicantId);
      if (response) {
        updateHelpPlan(true);
        setOpenModal(false);
        setDelete(false);
        setSelectedApplicantId("");
        toast.error("Delete Successfully");
      } else {
        console.error("Delete failed");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };
  const closeModal = () => {
    setOpenModal(false);
    setDelete(false);
    updateHelpPlan(false);
  };
  const delModalOpen = (applicantsid) => {
    setDelete(true);
    setOpenModal(true);
    setSelectedApplicantId(applicantsid);
  };
  const handleEditClick = (applicant: any): void => {
    formik.setValues({
      totalAmount: applicant.totalAmount || "",
      selectType: applicant.amountType || "",
      selectedUsers: Array.isArray(applicant.userId)
        ? applicant.userId
        : applicant.userId
        ? [applicant.userId]
        : [],
    });
    setOpenModal(true);
    setIsNewAdd(false);
    setSelectedApplicantId(applicant.vhpId);
  };

  const handlePageChange = (event: any, newPage: number): void => {
    updatePages(newPage + 1);
    updateIsCreate({ amount: "true" });
  };
  return (
    <>
      <Card>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          p={1}
          gap={2}
        >
          <Box>
            {" "}
            <TextField
              label="Search user id"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
            />
          </Box>
          <Box
            minWidth={310}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <TablePagination
              component="div"
              count={voluntaryData.total}
              onPageChange={handlePageChange}
              page={pages - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10]}
            />
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleCreateNew}
            >
              Add New
            </Button>
          </Box>
        </Box>
        <Divider />
        <TableContainer>
          <TableOverflow searchQuery={""}>
            {" "}
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 5,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Sr
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      User Id
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Total Amount
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {voluntaryData?.list?.map((data, index) => (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      <Typography variant="body1" fontWeight="bold">
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" fontWeight="bold">
                        {data.userId == "*" ? "All" : data.userId}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" fontWeight="bold">
                        {data.totalAmount}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" fontWeight="bold">
                        <Label
                          color={
                            data.amountType === "USED" ? "primary" : "info"
                          }
                        >
                          {data.amountType}
                        </Label>
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        fontSize={15}
                        noWrap
                        textAlign={"center"}
                        sx={{ width: "8rem" }}
                        component="div"
                      >
                        <IconButton onClick={() => handleEditClick(data)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => delModalOpen(data?.vhpId)}>
                          <DeleteIcon />
                        </IconButton>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableOverflow>
        </TableContainer>
      </Card>

      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          {isDelete ? (
            <Box mt={2} sx={{ textAlign: "center" }}>
              <Box>
                <Typography variant="h3">Are You Sure?</Typography>
              </Box>
              <Typography variant="body1">
                You are About to Delete this
              </Typography>
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  sx={{ mr: 1 }}
                >
                  Yes
                </Button>
                <Button variant="contained" onClick={closeModal} sx={{ ml: 3 }}>
                  No
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {" "}
              <Typography variant="h4" gutterBottom mb={3}>
                {isNewAdd ? "Create New" : "Update"}
              </Typography>
              <TextField
                label="Total Amount"
                variant="outlined"
                value={formik.values.totalAmount}
                onChange={(e) =>
                  formik.setFieldValue(
                    "totalAmount",
                    parseFloat(e.target.value)
                  )
                }
                fullWidth
                type="number"
                error={
                  formik.touched.totalAmount &&
                  Boolean(formik.errors.totalAmount)
                }
                helperText={
                  formik.touched.totalAmount && formik.errors.totalAmount
                }
              />
              {isNewAdd && (
                <>
                  {" "}
                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: formik.errors.selectType ? "red" : "",
                        },
                      }}
                    >
                      Select Type
                    </InputLabel>
                    <Select
                      value={formik.values.selectType}
                      onChange={handleSelectTypeChange}
                      label="Select Type"
                      error={
                        formik.touched.selectType &&
                        Boolean(formik.errors.selectType)
                      }
                    >
                      {Object.values(VhpAmountType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {type.replace(/-/g, " ")}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.selectType && formik.errors.selectType && (
                      <Typography color="error" variant="body2">
                        {formik.errors.selectType}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: formik.errors.selectedUsers ? "red" : "",
                        },
                      }}
                    >
                      Select Users
                    </InputLabel>
                    <Select
                      label="Select Users"
                      multiple
                      value={formik.values.selectedUsers}
                      onChange={(e) =>
                        formik.setFieldValue("selectedUsers", e.target.value)
                      }
                      renderValue={(selected) =>
                        selected.includes("*") ? "All" : selected.join(", ")
                      }
                      error={
                        formik.touched.selectedUsers &&
                        Boolean(formik.errors.selectedUsers)
                      }
                      MenuProps={MenuProps}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ mb: 1, ml: -1 }}
                        >
                          <Checkbox
                            checked={formik.values.selectedUsers.includes("*")}
                            disabled={searchQuery.length > 0}
                            onChange={() => handleCheckboxChange("*")}
                          />
                          <ListItemText primary="Select All" />
                        </Box>
                        <TextField
                          label="Search Users"
                          variant="outlined"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </Box>

                      <Box sx={{ marginTop: "15px" }}>
                        {userList.length > 0 &&
                          userList.map((user) => (
                            <MenuItem
                              key={user.id}
                              value={user.id}
                              sx={{
                                px: 0,
                                py: 1,
                                ml: -1,
                              }}
                            >
                              <Checkbox
                                checked={formik.values.selectedUsers.includes(
                                  user.id
                                )}
                                sx={{ ml: "3px" }}
                                onChange={() => handleCheckboxChange(user.id)}
                              />
                              <Box display={"flex"} flexDirection={"column"}>
                                <ListItemText
                                  primary={user.name || "-"}
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    color: "#333",
                                  }}
                                />
                                <ListItemText
                                  primary={user.userId}
                                  sx={{
                                    fontSize: "14px",
                                    color: "#555",
                                    ml: 0.1,
                                  }}
                                />
                              </Box>
                              <ListItemText
                                primary={`(${user.email})`}
                                sx={{
                                  ml: 1,
                                  color: "#555",
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                  flexWrap: "wrap",
                                }}
                              />
                            </MenuItem>
                          ))}
                      </Box>
                    </Select>
                    {formik.touched.selectedUsers &&
                      formik.errors.selectedUsers && (
                        <Typography color="error" variant="body2">
                          {formik.errors.selectedUsers}
                        </Typography>
                      )}
                  </FormControl>
                </>
              )}
              <Box textAlign="right" mt={3}>
                <Button
                  variant="contained"
                  onClick={() =>
                    isNewAdd ? formik.handleSubmit() : handleUpdateAmount()
                  }
                >
                  {isNewAdd ? "Create" : "Save Change"}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <ToastContainer autoClose={1000} style={{ marginTop: "70px" }} />
    </>
  );
};

export default VoluntaryDataTable;
