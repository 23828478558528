"use client";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { fetchPatientDetails } from "src/content/api";
const PatientTypography = ({ label, value, labelColor = "rgb(0,0,0,0.4)" }) => (
  <Typography
    display={"flex"}
    justifyContent={"start"}
    alignItems={"center"}
    fontWeight={800}
  >
    <Typography variant="h4" color={labelColor} mr={0.5}>
      {label}:
    </Typography>
    {value}
  </Typography>
);
const PatientDetails = () => {
  const [patientData, setPatientData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await fetchPatientDetails(token, id);
        setPatientData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [id]);
  const handleDownloadBill = async (url) => {
    const response = await fetch(url);

    if (!response.ok) {
      console.error("Failed to fetch file:", response.statusText);
      return;
    }

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "";
    link.click();

    URL.revokeObjectURL(link.href);
  };

  const handleViewBill = (url) => {
    setSelectedDoc({ src: url, label: "Bill Preview" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDoc(null);
  };

  return (
    <Box p={3}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{ p: 3, height: "100%" }}>
            <Typography variant="h3" gutterBottom>
              Patient Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
                gap={1.5}
              >
                <PatientTypography
                  label="Patient ID"
                  value={patientData.patientId || "-"}
                />
                <PatientTypography
                  label="Name"
                  value={patientData.patientName || "-"}
                />
                <PatientTypography
                  label="Aadhar No"
                  value={patientData.patientAadharNo || "-"}
                />
                <PatientTypography
                  label="Admission Date"
                  value={new Date(
                    patientData.hospitalizationDate
                  ).toLocaleDateString()}
                />
              </Box>
              <Box>
                <img
                  src="/assets/patient.png"
                  alt="Patient"
                  style={{
                    borderRadius: "8px",
                    width: "100px",
                    height: "130px",
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{ p: 3, height: "100%" }}>
            <Typography variant="h3" gutterBottom>
              Hospital Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="start"
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
                gap={1.5}
              >
                <PatientTypography
                  label="Hospital Name"
                  value={patientData.hospitalName || "-"}
                />
                <PatientTypography
                  label="Address"
                  value={patientData.hospitalAddress || "-"}
                />
              </Box>
              <Box>
                <img
                  src="/assets/hospital.png"
                  alt="Hospital"
                  style={{
                    borderRadius: "8px",
                    width: "160px",
                    height: "130px",
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{ p: 3, height: "100%" }}>
            <Typography variant="h3" gutterBottom>
              Discharge & Bills Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="start"
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
                gap={1.5}
              >
                <PatientTypography
                  label="Discharge Date"
                  value={new Date(
                    patientData.dischargeDate
                  ).toLocaleDateString()}
                />
                <Typography variant="h4" color={"rgb(0, 0, 0, 0.4)"}>
                  Bills Submitted:
                  {patientData.billsSubmitted ? (
                    <Chip
                      color="success"
                      label="Submitted"
                      sx={{ fontWeight: 700, marginLeft: 0.5 }}
                    />
                  ) : (
                    <Chip
                      color="error"
                      label="Not Submitted"
                      sx={{ fontWeight: 700, marginLeft: 0.5 }}
                    />
                  )}
                </Typography>
              </Box>
              <Box>
                <img
                  src="/assets/bill.png"
                  alt="Discharge"
                  style={{
                    borderRadius: "8px",
                    width: "130px",
                    height: "130px",
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Card variant="outlined" sx={{ p: 3, mb: 3 }}>
          <Typography variant="h3" gutterBottom>
            Bills
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    width: "20%",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="rgba(34, 51, 84, 0.7)"
                    gutterBottom
                    noWrap
                  >
                    Sr
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="rgba(34, 51, 84, 0.7)"
                    gutterBottom
                    noWrap
                  >
                    File Name
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="rgba(34, 51, 84, 0.7)"
                    gutterBottom
                    noWrap
                  >
                    Admission Date
                  </Typography>
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                      }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientData.patientBills?.map((bill, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      fontSize={15}
                      textAlign={"center"}
                      noWrap
                    >
                      {index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      fontSize={15}
                      textAlign={"center"}
                      noWrap
                    >
                      {bill.split("_")[1]}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      fontSize={15}
                      textAlign={"center"}
                      noWrap
                    >
                      {patientData.hospitalizationDate &&
                        new Date(
                          patientData.hospitalizationDate
                        ).toLocaleDateString()}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleViewBill(patientData.billPreview[index])
                      }
                      sx={{
                        minWidth: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        padding: 0,
                      }}
                    >
                      <VisibilityIcon />
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        handleDownloadBill(patientData.billPreview[index])
                      }
                      sx={{
                        minWidth: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        padding: 0,
                        ml: 2,
                      }}
                    >
                      <DownloadIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Box>

      {selectedDoc && (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>{selectedDoc.label}</DialogTitle>
          <DialogContent>
            <img
              src={selectedDoc.src}
              alt={selectedDoc.label}
              height="999"
              width="999"
              style={{ width: "100%", height: "auto" }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default PatientDetails;
