import Footer from "src/components/Footer";
import { Helmet } from "react-helmet-async";
import PatientDetails from "./PatientDetails";
import { Grid, Typography } from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";

export default function Patient() {
  return (
    <>
      <Helmet>
        <title>Hwp Patient</title>
        <link rel="icon" href="/AIM HWP_SVG.svg" />
      </Helmet>
      <PageTitleWrapper>
        <Grid marginLeft={4}>
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Patient Details
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Grid px={5}>
        {" "}
        <PatientDetails />
      </Grid>
      <Footer />
    </>
  );
}
