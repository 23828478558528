import { FC, useState, useEffect } from "react";
import {
  Divider,
  Box,
  Button,
  Card,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Download as DownloadIcon } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { fetchUserDataList, sendNotification } from "src/content/api";
import { useUserDataContext } from "src/hooks/userDataContext";
import { NotificationTypes } from "../Enum";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TableOverflow } from "../components/CustomeItem";

interface NotificationDataTableProps {
  notifications: any;
  handleSearch: any;
  searchValue: any;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "380px",
      width: 250,
    },
  },
};
const NotificationDataTable: FC<NotificationDataTableProps> = ({
  notifications,
  handleSearch,
  searchValue,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [userList, setUserList] = useState<any>([]);
  const [listLength, setListLength] = useState<any>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState(false);
  const { updateNotification, updateIsCreate, updatePages, pages } =
    useUserDataContext();
  const token = localStorage.getItem("token");
  const [limit] = useState<number>(10);
  const validationSchema = Yup.object({
    notificationTitle: Yup.string().required("Notification title is required"),
    notificationBody: Yup.string().required("Notification body is required"),
    selectType: Yup.string().required("Select type is required"),
    selectedUsers: Yup.array().min(1, "At least one user must be selected"),
  });

  const formik = useFormik({
    initialValues: {
      notificationTitle: "",
      notificationBody: "",
      selectType: "",
      selectedUsers: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const { notificationTitle, notificationBody, selectType, selectedUsers } =
        values;

      try {
        if (selectedUsers.length === 0) {
          toast.error("Please select at least one user.");
          return;
        }

        const userIds = selectedUsers[0] === "*" ? ["*"] : selectedUsers;
        const payload = {
          userIds: userIds,
          notificationTitle: notificationTitle,
          notificationBody: notificationBody,
          notificationType: selectType,
        };

        const response = await sendNotification(
          token,
          payload.userIds,
          payload.notificationTitle,
          payload.notificationBody,
          payload.notificationType
        );
        if (response) {
          toast.success("Notification sent successfully!");
          updateNotification(true);
          setOpenModal(false);
        } else {
          toast.error("Error sending notification.");
        }
      } catch (error) {
        console.error("Error while sending notification:", error);
        toast.error("An unexpected error occurred.");
      }
    },
  });

  const handleCheckboxChange = (value: string) => {
    let newSelectedUsers = [...selectedUsers];

    if (value === "*") {
      newSelectedUsers = selectedUsers.includes("*")
        ? []
        : ["*", ...userList.map((user) => user.id)];
    } else {
      if (selectedUsers.includes(value)) {
        newSelectedUsers = newSelectedUsers.filter(
          (id) => id !== value && id !== "*"
        );
      } else {
        if (newSelectedUsers.length == listLength - 1) {
          newSelectedUsers = ["*", ...userList.map((user) => user.id)];
        } else {
          newSelectedUsers = [...newSelectedUsers, value];
        }
      }
    }

    setSelectedUsers(newSelectedUsers);
    formik.setFieldValue("selectedUsers", newSelectedUsers);
  };

  const handleSelectTypeChange = (e: any) => {
    const newSelectType = e.target.value;
    formik.setFieldValue("selectType", newSelectType);
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
    setSearch(true);
  };

  const handleCreateNew = () => {
    setOpenModal(true);
    setSearchQuery("");
    formik.resetForm();
    setSelectedUsers([]);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newDataList = await fetchUserDataList(searchQuery);
        setUserList(newDataList.list);
        if (searchQuery === "") {
          setListLength(newDataList.list.length);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
    if (searchQuery === "") {
      setSearch(false);
    }
  }, [searchQuery, search, openModal]);
  const handleDownloadImage = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      console.error("Failed to fetch file:", response.statusText);
      return;
    }

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "";
    link.click();

    URL.revokeObjectURL(link.href);
  };
  const handlePageChange = (event: any, newPage: number): void => {
    updatePages(newPage + 1);
    updateIsCreate({ notification: "true" });
  };
  return (
    <>
      <Card>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          p={1}
          gap={2}
        >
          <Box>
            {" "}
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
            />
          </Box>
          <Box
            minWidth={350}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <TablePagination
              component="div"
              count={notifications.total}
              onPageChange={handlePageChange}
              page={pages - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10]}
            />
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleCreateNew}
            >
              New Message
            </Button>
          </Box>
        </Box>
        <Divider />
        <TableContainer>
          <TableOverflow searchQuery={""}>
            {" "}
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 5,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Sr
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      User id
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Title
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Body
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={`rgba(34, 51, 84, 0.7)`}
                      gutterBottom
                      noWrap
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications?.list?.map((data, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        fontSize={15}
                        noWrap
                        align="center"
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        fontSize={15}
                        noWrap
                        align="center"
                      >
                        {data.userPrimaryId == "*" ? "All" : data.userPrimaryId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={15}
                        align="center"
                      >
                        {data.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={15}
                        align="center"
                      >
                        {data.body}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={15}
                        align="center"
                      >
                        {data.type}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {data.type === "need-img-upload" ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            handleDownloadImage(data.fileUrlPreview)
                          }
                          sx={{
                            minWidth: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            padding: 0,
                          }}
                          disabled={!data.fileUrlPreview}
                        >
                          <DownloadIcon />
                        </Button>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableOverflow>
        </TableContainer>
      </Card>
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h4" gutterBottom mb={3}>
            Create New Notification
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Title"
              variant="outlined"
              value={formik.values.notificationTitle}
              onChange={formik.handleChange}
              name="notificationTitle"
              fullWidth
              sx={{ mb: 2 }}
              error={
                formik.touched.notificationTitle &&
                Boolean(formik.errors.notificationTitle)
              }
              helperText={
                formik.touched.notificationTitle &&
                formik.errors.notificationTitle
              }
            />
            <TextField
              label="Message"
              variant="outlined"
              value={formik.values.notificationBody}
              onChange={formik.handleChange}
              name="notificationBody"
              fullWidth
              multiline
              rows={4}
              error={
                formik.touched.notificationBody &&
                Boolean(formik.errors.notificationBody)
              }
              helperText={
                formik.touched.notificationBody &&
                formik.errors.notificationBody
              }
            />

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: formik.errors.selectType ? "red" : "",
                  },
                }}
              >
                Select Type
              </InputLabel>
              <Select
                value={formik.values.selectType}
                onChange={formik.handleChange}
                name="selectType"
                label="Select Type"
                error={
                  formik.touched.selectType && Boolean(formik.errors.selectType)
                }
              >
                {Object.values(NotificationTypes).map((type) => (
                  <MenuItem key={type} value={type} sx={{ py: 1 }}>
                    {type.replace(/-/g, " ")}{" "}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.selectType && formik.errors.selectType && (
                <Typography color="error" variant="body2">
                  {formik.errors.selectType}
                </Typography>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: formik.errors.selectedUsers ? "red" : "",
                  },
                }}
              >
                Select Users
              </InputLabel>
              <Select
                label="Select Users"
                multiple
                value={formik.values.selectedUsers}
                onChange={(e) =>
                  formik.setFieldValue("selectedUsers", e.target.value)
                }
                renderValue={(selected) =>
                  selected.includes("*") ? "All" : selected.join(", ")
                }
                error={
                  formik.touched.selectedUsers &&
                  Boolean(formik.errors.selectedUsers)
                }
                MenuProps={MenuProps}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: 1, ml: -1 }}
                  >
                    <Checkbox
                      checked={formik.values.selectedUsers.includes("*")}
                      onChange={() => handleCheckboxChange("*")}
                      disabled={searchQuery.length > 0}
                    />
                    <ListItemText primary="Select All" />
                  </Box>
                  <TextField
                    label="Search Users"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                  />
                </Box>

                <Box sx={{ marginTop: "15px" }}>
                  {userList.length > 0 &&
                    userList.map((user) => (
                      <MenuItem
                        key={user.id}
                        value={user.id}
                        sx={{
                          px: 0,
                          py: 1,
                          ml: -1,
                        }}
                      >
                        <Checkbox
                          checked={formik.values.selectedUsers.includes(
                            user.id
                          )}
                          sx={{ ml: "3px" }}
                          onChange={() => handleCheckboxChange(user.id)}
                        />
                        <Box display={"flex"} flexDirection={"column"}>
                          <ListItemText
                            primary={user.name || "-"}
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "#333",
                            }}
                          />
                          <ListItemText
                            primary={user.userId}
                            sx={{
                              fontSize: "14px",
                              color: "#555",
                              ml: 0.1,
                            }}
                          />
                        </Box>
                        <ListItemText
                          primary={`(${user.email})` || "(testing@gmail.com)"}
                          sx={{
                            ml: 1,
                            color: "#555",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            flexWrap: "wrap",
                          }}
                        />
                      </MenuItem>
                    ))}
                </Box>
              </Select>
              {formik.touched.selectedUsers && formik.errors.selectedUsers && (
                <Typography color="error" variant="body2">
                  {formik.errors.selectedUsers}
                </Typography>
              )}
            </FormControl>
            <Box textAlign="right" mt={3}>
              <Button variant="contained" color="primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <ToastContainer autoClose={1000} style={{ marginTop: "70px" }} />
    </>
  );
};

export default NotificationDataTable;
