import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import { Item3 } from "./CustomeItem";

export const OtherDetails = (otherdetails) => {
  const formattedBirthdate = otherdetails?.otherdetails?.nominees?.birthdate
    ? new Date(otherdetails?.otherdetails?.nominees?.birthdate)
        .toISOString()
        .split("T")[0]
    : "-";
  const nomineesDetails = [
    {
      label: "Name",
      value: otherdetails?.otherdetails?.nominees?.name || "-",
    },
    {
      label: "Phone",
      value: otherdetails?.otherdetails?.nominees?.phone || "-",
    },
    {
      label: "Date Of Birth",
      value: formattedBirthdate,
    },
    { label: "Address", value: otherdetails?.otherdetails?.nominees?.address },
  ];
  // const sipDetails = [
  //   { label: "Name", value: "abc" },
  //   { label: "Amount", value: "1000" },
  //   { label: "Bank", value: "HDFC" },
  // ];

  const bankDetails = [
    {
      label: "Holder Name",
      value:
        otherdetails?.otherdetails?.bankDetails?.accountHolderName || "-",
    },
    {
      label: "Bank Name",
      value: otherdetails?.otherdetails?.bankDetails?.bankName || "-",
    },
    {
      label: "Account Number",
      value: otherdetails?.otherdetails?.bankDetails?.accountNumber || "N/A",
    },
    {
      label: "Branch",
      value: otherdetails?.otherdetails?.bankDetails?.branch || "N/A",
    },
    {
      label: "IFSC Code",
      value: otherdetails?.otherdetails?.bankDetails?.ifscCode || "N/A",
    },
  ];

  return (
    <>
      <Grid xs={12} mt={3}>
        <Accordion
          style={{
            border: "1px solid rgb(0,0,0,0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreTwoToneIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography variant='h3' ml={1}>
              Other Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              xs={12}
              display={"flex"}
              height={"auto"}
              padding={2}
              spacing={2}
              justifyContent={"center"}
              flexWrap={"wrap"}
            >
              <Grid item xs={6}>
                <Item3 style={{ height: "37vh" }}>
                  <TableContainer>
                    <Box className='boxscroll'>
                      <Typography variant='h3' ml={1} color={"white"}>
                        Bank Details
                      </Typography>
                    </Box>
                    <Table
                      sx={{
                        "& tbody tr:last-child td": {
                          borderBottom: 0,
                        },
                      }}
                      aria-label='simple table'
                    >
                      <TableBody>
                        {bankDetails.map((details, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant='h4' color={"rgb(0,0,0,0.4)"}>
                                {details.label}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='h4' color={"rgb(0,0,0,0.7)"}>
                                {details.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item3>
              </Grid>
              <Grid item xs={6}>
                <Item3 style={{ height: "37vh" }}>
                  <TableContainer>
                    <Box className='boxscroll'>
                      <Typography variant='h3' ml={1} color={"white"}>
                        Nominees Details
                      </Typography>
                    </Box>
                    <Table
                      sx={{
                        "& tbody tr:last-child td": {
                          borderBottom: 0,
                        },
                      }}
                      aria-label='simple table'
                    >
                      <TableHead></TableHead>
                      <TableBody>
                        {nomineesDetails.map((detail, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant='h4' color={"rgb(0,0,0,0.4)"}>
                                {detail.label}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='h4' color={"rgb(0,0,0,0.7)"}>
                                {detail.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item3>
              </Grid>
              {/* <Grid item xs={6}>
                <Item3>
                  <TableContainer>
                    <Box className='boxscroll'>
                      <Typography variant='h3' ml={1} color={"white"}>
                        SIP Details
                      </Typography>
                    </Box>
                    <Table
                      sx={{
                        "& tbody tr:last-child td": {
                          borderBottom: 0,
                        },
                      }}
                      aria-label='simple table'
                    >
                      <TableBody>
                        {sipDetails.map((detail, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant='h4' color={"rgb(0,0,0,0.4)"}>
                                {detail.label}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='h4' color={"rgb(0,0,0,0.7)"}>
                                {detail.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item3>
              </Grid> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
