import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import Footer from "src/components/Footer";
import UserData from "./UserDetails";

import DownloadIcon from "@mui/icons-material/Download";
import { useUserDataContext } from "src/hooks/userDataContext";
import handleDownload from "../components/GenratePdf";
import { useNavigate, useParams } from "react-router";
import { URL_CONFIG } from "src/config/urlConfig";
import { toast } from "react-toastify";
import { fetchUserDetailsId } from "src/content/api";

export default function ViewUserDetails() {
  const { applicantId } = useParams();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const navigate = useNavigate();
  const { appDataList, cmsPages } = useUserDataContext();
  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
  };
  const handleChangePassword = () => {
    setOpenPasswordModal(false);
  };
  const handleClick = () => {
    handleDownload([appDataList], cmsPages);
  };
  const PageTitle = styled(Box)(
    ({ theme }) => `
        padding: ${theme.spacing(4)};
        background:white !important;
`
  );
  const handleApproveDeleteReq = async (id: string) => {
    try {
      const response = await fetch(
        `${URL_CONFIG.BASE_URL}/admin/api/v1/users/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        navigate("/management/userdetails");
      } else {
        toast.error("Delete failed");
      }
    } catch (error) {
      toast.error("Error during deletion");
      console.error("Error during deletion:", error);
    }
  };
  return (
    <>
      <PageTitle className="MuiPageTitle-wrapper" sx={{ width: "100%" }}>
        <Container maxWidth="lg">
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            ml={-2}
          >
            <Grid>
              <Typography variant="h3" component="h3" gutterBottom>
                View User Details
              </Typography>
            </Grid>
            <Grid>
              {appDataList?.requestedForDeleteAccount && (
                <Button
                  sx={{
                    mr: 2,
                    color: "red",
                    borderColor: "red",
                    "&:hover": {
                      backgroundColor: "red",
                      color: "white",
                      borderColor: "red",
                    },
                  }}
                  variant="outlined"
                  onClick={() => handleApproveDeleteReq(applicantId)}
                >
                  Approve Delete Request
                </Button>
              )}
              <Button variant="outlined" onClick={handleOpenPasswordModal}>
                Change Password
              </Button>

              <IconButton
                color="primary"
                sx={{ ml: 2, border: "1px solid" }}
                onClick={handleClick}
              >
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </PageTitle>
      <Container>
        <Grid container justifyContent="center" spacing={2} mb={10}>
          <UserData
            handleChangePassword={handleChangePassword}
            openPasswordModal={openPasswordModal}
            handleClosePasswordModal={handleClosePasswordModal}
          />
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
