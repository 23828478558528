import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "../components/PageHeader";
import Footer from "src/components/Footer";
import { Helmet } from "react-helmet-async";
import TableData from "src/components/Table";
import VoluntaryData from "./voluntaryHelpData";

export default function VoluntaryHelp() {
  return (
    <>
      <Helmet>
        <title>Hwp VSHP Amount</title>
        <link rel="icon" href="/AIM HWP_SVG.svg" />
      </Helmet>
      <PageTitleWrapper>
        <PageHeader Title={"Voluntary Help Data"} />
      </PageTitleWrapper>
      <TableData Data={<VoluntaryData />}></TableData>
      <Footer />
    </>
  );
}
