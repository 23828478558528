import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import VoluntaryDataTable from "./voluntaryHelpDataTable";
import { fetchVoluntaryData } from "src/content/api";
import { useUserDataContext } from "src/hooks/userDataContext";

function VoluntaryData() {
  const [voluntaryData, setVoluntaryData] = useState<any>([]);
  const token = localStorage.getItem("token");
  const { isAddHelpPlan, pages, isCreate, updatePages } = useUserDataContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const pageNo = pages;
      try {
        const newDataList = await fetchVoluntaryData(
          token,
          searchQuery.toLowerCase() === "all" ? "*" : searchQuery,
          pageNo
        );
        setVoluntaryData(newDataList?.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [token, isAddHelpPlan, isCreate, pages, searchQuery, search]);
   useEffect(() => {
     updatePages(1);
   }, []);
  const handleSearchChange = (event: any) => {
    setSearch(true);
    updatePages(1);
    setSearchQuery(event.target.value);
  };
  return (
    <Card style={{ width: "100%" }}>
      <VoluntaryDataTable
        voluntaryData={voluntaryData}
        handleSearch={handleSearchChange}
        searchValue={searchQuery}
      />{" "}
    </Card>
  );
}

export default VoluntaryData;
