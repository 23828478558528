import * as React from "react";
import Box from "@mui/material/Box";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { PersonalDetails } from "../components/PersonalDetails";
import { FamilyDetails } from "../components/FamilyDetails";
import { OtherDetails } from "../components/OtherDetails";
import Password from "./Password";
import { useEffect } from "react";
import { useParams } from "react-router";

import { useUserDataContext } from "src/hooks/userDataContext";
import { fetchUserDetailsId } from "src/content/api";

export default function BasicGrid({
  openPasswordModal,
  handleClosePasswordModal,
  handleChangePassword,
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState<any>({});

  const view = (doc) => {
    setSelectedDoc(doc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { applicantId } = useParams();
  const { appDataList, updateAppDataList, approvals } = useUserDataContext();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const newDataList = await fetchUserDetailsId(applicantId);
        updateAppDataList(newDataList);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [approvals]);

  const checkDoc = "";
  return (
    <>
      <Box flex={1}>
        <PersonalDetails
          view={view}
          personalData={appDataList}
          checkDoc={checkDoc}
          ShowBtn={"false"}
        />
        <FamilyDetails
          familydetails={appDataList}
          // handleAction={handleAction}
        />
        <OtherDetails otherdetails={appDataList} />
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedDoc?.label}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "50vh",
          }}
        >
          <img
            src={selectedDoc?.src}
            alt={selectedDoc?.label}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openPasswordModal} onClose={handleClosePasswordModal}>
        <DialogContent sx={{ width: "25rem", padding: "35px" }}>
          <Password handleChangePassword={handleChangePassword} />
        </DialogContent>
      </Dialog>
    </>
  );
}
