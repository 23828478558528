import {
  Card,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CardHeader,
  TextField,
  Box,
  TablePagination,
} from "@mui/material";
import Label from "src/components/Label";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { AppDataStatus } from "src/models/app";
import { NavLink } from "react-router-dom";
import { useUserDataContext } from "src/hooks/userDataContext";
import { useState } from "react";

const getStatusLabel = (AppDataStatus: AppDataStatus): JSX.Element => {
  const map = {
    false: {
      text: "False",
      color: "error",
    },
    true: {
      text: "True",
      color: "success",
    },
  };
  const { text, color }: any = map[AppDataStatus];

  return <Label color={color}>{text}</Label>;
};
const PatientDataTable = ({ patientList, handleSearch, searchValue }) => {
  const { updatePages, updateIsCreate, pages } = useUserDataContext();
  const [limit] = useState<number>(10);
  const handlePageChange = (event: any, newPage: number): void => {
    updatePages(newPage + 1);
    updateIsCreate({ patient: "true" });
  };
  return (
    <Card>
      <CardHeader
        action={
          <Box
            p={1}
            width={450}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
            />
            <TablePagination
              component="div"
              count={patientList.total}
              onPageChange={handlePageChange}
              page={pages - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10]}
            />
          </Box>
        }
      />
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              background: "white",
              zIndex: 5,
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "10%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  Record ID
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "20%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  Patient Name
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "10%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  User ID
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "20%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  Aadhar No
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "20%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  Hospital Name
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "20%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  Admission Date
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "15%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  noWrap
                  gutterBottom
                >
                  Discharge Date
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  width: "15%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="rgba(34, 51, 84, 0.7)"
                  gutterBottom
                  noWrap
                >
                  Bills Submitted
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  width: "6rem",
                  textAlign: "center",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientList?.list?.map((data, index) => (
              <TableRow hover key={index}>
                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {data.patientId}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {data.patientName}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {data.userId}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {data.patientAadharNo}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {data.hospitalName}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {(data.hospitalizationDate &&
                      new Date(
                        data.hospitalizationDate
                      )?.toLocaleDateString()) ||
                      "-"}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    textAlign={"center"}
                    noWrap
                  >
                    {" "}
                    {(data.dischargeDate &&
                      new Date(data.dischargeDate)?.toLocaleDateString()) ||
                      "-"}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={17}
                    textAlign={"center"}
                    noWrap
                  >
                    {" "}
                    {getStatusLabel(data.billsSubmitted)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    fontSize={15}
                    noWrap
                    textAlign={"center"}
                    sx={{ width: "6rem" }}
                    component={NavLink}
                    to={`../patient/${data.patientId}`}
                  >
                    <IconButton color="primary">
                      <VisibilityIcon />
                    </IconButton>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientDataTable;
