import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "../components/PageHeader";
import Footer from "src/components/Footer";
import { Helmet } from "react-helmet-async";
import PatientData from "./PatientData";
import TableData from "src/components/Table";

export default function Patient() {
  return (
    <>
      <Helmet>
        <title>Hwp Patient</title>
        <link rel="icon" href="/AIM HWP_SVG.svg" />
      </Helmet>
      <PageTitleWrapper>
        <PageHeader Title={"Patient Data"} />
      </PageTitleWrapper>
      <TableData Data={<PatientData />}></TableData>
      <Footer />
    </>
  );
}
