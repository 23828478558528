export enum NotificationTypes {
  CUSTOM = "custom",
  NEED_IMG_UPLOAD = "need-img-upload",
}

export enum VolunteerFileType {
  HELP_PLAN = "help-plan",
  HELP_TRANSACTION = "help-transaction",
}

export enum VhpAmountType {
  RECEIVED = "RECEIVED",
  USED = "USED",
}
