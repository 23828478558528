import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "../components/PageHeader";
import TableData from "src/components/Table";
import { useUserDataContext } from "src/hooks/userDataContext";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { URL_CONFIG } from "src/config/urlConfig";
import Footer from "src/components/Footer";

const RichTextBox = () => {
  const { cmsPages, updateCmsPages }: any = useUserDataContext();

  const { cmsPageId } = useParams();
  const pageid = cmsPageId;
  const [content, setContent] = useState();
  const handleChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${URL_CONFIG.BASE_URL}/admin/api/v1/cms-pages/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cmsPageId: pageid,
            content: content,
          }),
        }
      );

      if (response.ok) {
        const updatedPages = cmsPages.map((page) =>
          page.cmsPageId === pageid ? { ...page, content: content } : page
        );
        updateCmsPages(updatedPages);
        toast.success("Content updated successfully!");
      } else {
        console.error("Failed to update content");
      }
    } catch (error) {
      console.error("Error updating content:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Hwp Cms-Page</title>
        <link rel="icon" href="/AIM HWP_SVG.svg" />
      </Helmet>
      {cmsPages
        .filter((page) => page.cmsPageId === pageid)
        .map((filteredPage) => (
          <React.Fragment key={filteredPage.cmsPageId}>
            <PageTitleWrapper>
              <PageHeader Title={`Edit ${filteredPage.title}`} />
            </PageTitleWrapper>
            <TableData
              key={filteredPage.cmsPageId}
              Data={
                <>
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: `<p>${filteredPage.content}</p>`,
                    }}
                  ></div> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={`${filteredPage.content}`}
                    onChange={handleChange}
                  />
                  <Box display={"flex"} mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "auto" }}
                      onClick={handleSave}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </>
              }
            />
          </React.Fragment>
        ))}
      <ToastContainer autoClose={1000} style={{ marginTop: "70px" }} />
    </>
  );
};

export default RichTextBox;
