import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function generatePDF(data, cmsdata) {
  var currentDate = new Date();

  var userid, enrollment;
  data.map((item) => {
    userid = `AIM${item?.userId}`;
    enrollment = item?.membershipType;
  });
  var date = currentDate.toLocaleDateString();
  var time = currentDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const doc = new jsPDF();
  var maxWidth = 200;
  var pageHeight = doc.internal.pageSize.height;

  function addContent(pageNumber) {
    if (pageNumber === 1) {
      doc.setFontSize(25);
      doc.rect(8, 8, 195, 36);
      doc.addImage("/AIM HWP_SVG.png", "PNG", 15, 10, 20, 20);

      // Company name
      doc.setFont("helvetica", "bold");
      doc.text("AIM HWP SERVICES LLP", 55, 20);

      // Slogan
      doc.setFont("helvetica");
      doc.setFontSize(10);
      doc.text(`"Keep Smiling As Humanity Is Still Alive"`, 70, 30);

      // User ID and Type of Enrollment
      doc.setFontSize(8);
      doc.text(`User Id: ${userid || "-"}`, 10, 35);
      doc.text(`Type of Enrollment: ${enrollment || ""}`, 10, 41);

      // Date and Time
      doc.text("Date:-", 170, 35);
      doc.text(date, 179, 35);
    } else {
      doc.rect(8, 8, 195, pageHeight - 16); // Border for each page
    }
  }

  addContent(1);

  let startY = 90;
  addTitleAndContent(doc, 53);

  startY = generatePersonalDataTable(doc, data, startY);
  startY = generateFamilyDetails(doc, data, startY);
  startY = generateNomineeDetails(doc, data, startY);
  startY = generateBankDetails(doc, data, startY);

  addDeclaration(doc, startY);

  const pageCount = doc.internal.pages.length;

  for (let i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    addContent(i);
  }

  doc.save(`Hwp-user-Id_${userid}.pdf`);
}

// function addWatermarkToCenter(doc, watermarkLogo) {
//   const pageSize = doc.internal.pageSize;
//   const pageWidth = pageSize.getWidth();
//   const pageHeight = pageSize.getHeight();
//   const logoWidth = 50; // Adjust the width of the logo as needed
//   const logoHeight = (logoWidth * watermarkLogo.height) / watermarkLogo.width;

//   doc.addImage(
//     watermarkLogo,
//     "PNG",
//     (pageWidth - logoWidth) / 2,
//     (pageHeight - logoHeight) / 2,
//     logoWidth,
//     logoHeight
//   );
// }
function addTitleAndContent(doc, startY) {
  const maxWidth = 210; // Total width of the content area
  const marginLeft = 15; // Left margin
  const lineHeight = 6; // Line height between lines
  var title = `Object`;
  var content =
    "Cultivate a sense of community among our Users, encouraging voluntary support mechanisms. This involves creating avenues for Users to share experiences, insights and even provide any type of assistance to fellow Users. The goal is to build a supportive network where individuals collectively work towards achieving common health and wealth protection goals.";

  // Add the title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(15);
  doc.text(title, 14, startY);
  startY += 8;

  // Add the content
  doc.setFont("helvetica");
  doc.setFontSize(10);
  doc.setTextColor(20);

  // Split content into words
  const words = content.split(" ");
  let line = [];
  let lineWidth = 0;

  // Process each word and create justified lines
  words.forEach((word, index) => {
    const wordWidth = doc.getTextWidth(word + " "); // Add space after word

    if (lineWidth + wordWidth <= maxWidth - marginLeft * 2) {
      line.push(word);
      lineWidth += wordWidth;
    } else {
      // Justify the current line
      justifyLine(doc, line, startY, marginLeft, maxWidth, false);
      // Reset line and start with the current word
      line = [word];
      lineWidth = wordWidth;
      startY += lineHeight;
    }
  });

  // Justify the last line (handle case for 1 or 2 words)
  if (line.length > 0) {
    justifyLine(doc, line, startY, marginLeft, maxWidth, true);
  }

  return startY + 20;
}

function generatePersonalDataTable(doc, data, startY) {
  doc.setFontSize(16);
  doc.text("Personal Details", 14, startY);
  const tableData = data?.map((user) => [
    `AIM${user?.userId}`,
    user.name || "-",
    user.email,
    new Date(user.birthdate).toLocaleDateString(),
    user.phone,
    user.membershipType,
    user.status,
  ]);

  autoTable(doc, {
    head: [
      [
        "User Id",
        "Name",
        "Email",
        "Date Of Birth",
        "Mobile No",
        "Enrollment Type",
        "Status",
      ],
    ],
    body: tableData,
    startY: startY + 5,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.1,
    pageBreak: "auto",
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    didDrawPage: function (data) {
      doc.rect(8, 8, 195, 280);
      if (data.cursor.y > 280) {
        doc.addPage();
        startY = 48;
      }
    },
  });
  return doc.autoTable.previous.finalY + 10;
}

function generateFamilyDetails(doc, data, startY) {
  doc.setFontSize(16);
  doc.text("Family Details", 14, startY);

  const familyData = data[0]?.familyMembers?.map((user) => [
    user.name,
    new Date(user.birthdate).toLocaleDateString(),
    user.relationType,
  ]);

  autoTable(doc, {
    head: [["Name", "Date of Birth", "Relationship"]],
    body: familyData,
    pageBreak: "auto",
    startY: startY + 5,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.1,

    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    didDrawPage: function (data) {
      doc.rect(8, 8, 195, 280);
      if (data.cursor.y > 280) {
        doc.addPage();
        startY = 48;
      }
    },
  });
  return doc.autoTable.previous.finalY + 10;
}

function generateNomineeDetails(doc, data, startY) {
  doc.setFontSize(16);
  doc.text("Nominees Details", 14, startY);

  const NomineesData = [data[0]?.nominees];
  const nomineeData = NomineesData?.map((user) => [
    user.name,
    user.phone,
    new Date(user.birthdate).toLocaleDateString(),
    user.address,
  ]);

  autoTable(doc, {
    head: [["Name", "Phone", "Date of Birth", "Address"]],
    body: nomineeData,
    startY: startY + 5,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.1,
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    didDrawPage: function (data) {
      doc.rect(8, 8, 195, 280);
      if (data.cursor.y > 280) {
        doc.addPage();
        startY = 48;
      }
    },
  });
  return doc.autoTable.previous.finalY + 10;
}

function generateBankDetails(doc, data, startY) {
  doc.setFontSize(16);
  doc.text("Bank Details", 14, startY);

  const bankDetails = data[0]?.bankDetails;

  const bankData = bankDetails
    ? [
        ["Holder Name", bankDetails.accountHolderName],
        ["Bank Name", bankDetails.bankName],
        ["Account Number", bankDetails.accountNumber],
        ["Branch", bankDetails.branch],
        ["IFSC Code", bankDetails.ifscCode],
      ]
    : [];

  autoTable(doc, {
    head: [["Field", "Value"]],
    body: bankData,
    startY: startY + 5,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.1,
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    didDrawPage: function (data) {
      doc.rect(8, 8, 195, 280);
      if (data.cursor.y > 280) {
        doc.addPage();
        startY = 48;
      }
    },
  });
  return doc.autoTable.previous.finalY + 15;
}
function addDeclaration(doc, startY) {
  const maxWidth = 210; // Total width of the content area
  const maxHeight = 280; // Page height limit
  const marginLeft = 15; // Left margin
  const lineHeight = 6; // Line height between lines

  const title = "Declaration";
  const content = `I clearly understand the Voluntary Self Help Plan offered by the AIM HWP SERVICES. AIM HWP SERVICES LLP also offers other services i.e. an investment advices related to investment in Mutual fund through AMFI registered Mutual fund distributor etc. In Voluntary Self Help Plan the Enrollers (Users) are Voluntarily doing direct help and providing assistance to the other Users in case of need. No money, other than service charges, is transferred to the AIM HWP SERVICES LLP. Hence, no Enroller (User) can claim direct monetary relief from the ‘AIM HWP SERVICES LLP’. Cultivate a sense of community among our Users, encouraging voluntary support mechanisms. This involves creating avenues for Users to share experiences, insights and even provide any type of assistance to fellow Users. The goal is to build a supportive network where individuals collectively work towards achieving common health and wealth protection goals.I clearly understand the Voluntary Self Help Plan offered by the AIM HWP SERVICES. AIM HWP SERVICES LLP also offers other services i.e. an investment advices related to investment in Mutual fund through AMFI registered Mutual fund distributor etc. In Voluntary Self Help Plan the Enrollers (Users) are Voluntarily doing direct help and providing assistance to the other Users in case of need. No money, other than service charges, is transferred to the AIM HWP SERVICES LLP. Hence, no Enroller (User) can claim direct monetary relief from the ‘AIM HWP SERVICES LLP’. Cultivate a sense of community among our Users, encouraging voluntary support mechanisms. This involves creating avenues for Users to share experiences, insights and even provide any type of assistance to fellow Users. The goal is to build a supportive network where individuals collectively work towards achieving common health and wealth protection goals.`;

  // Add the title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(15);
  doc.text(title, 14, startY);
  startY += 10;

  // Add the content
  doc.setFont("helvetica");
  doc.setFontSize(10);
  doc.setTextColor(20);

  // Split content into words
  const words = content.split(" ");
  let line = [];
  let lineWidth = 0;

  // Process each word and create justified lines
  words.forEach((word, index) => {
    const wordWidth = doc.getTextWidth(word + " "); // Add space after word

    if (lineWidth + wordWidth <= maxWidth - marginLeft * 2) {
      line.push(word);
      lineWidth += wordWidth;
    } else {
      // Justify the current line
      justifyLine(doc, line, startY, marginLeft, maxWidth, false);

      // Reset line and start with the current word
      line = [word];
      lineWidth = wordWidth;
      startY += lineHeight;

      if (startY > maxHeight) {
        doc.addPage();
        startY = 15;
      }
    }
  });

  // Justify the last line (handle case for 1 or 2 words)
  if (line.length > 0) {
    justifyLine(doc, line, startY, marginLeft, maxWidth, true);
  }

  return startY + 20;
}

// Function to justify a line (distribute words across the line width)
function justifyLine(doc, line, startY, marginLeft, maxWidth, isLastLine) {
  const spaceWidth = doc.getTextWidth(" "); // Width of a single space
  const lineLength = line.reduce(
    (sum, word) => sum + doc.getTextWidth(word),
    0
  ); // Total width of the words in the line

  const totalSpace = maxWidth - marginLeft * 2 - lineLength;
  const spaceBetweenWords =
    line.length > 1 ? totalSpace / (line.length - 1) : 0;

  let xPosition = marginLeft;

  if (isLastLine) {
    // Left align the last line (avoid excessive space between two words)
    line.forEach((word, index) => {
      doc.text(word, xPosition, startY);
      xPosition += doc.getTextWidth(word) + spaceWidth;
    });
  } else {
    // Justify all other lines
    line.forEach((word, index) => {
      doc.text(word, xPosition, startY);
      if (index < line.length - 1) {
        xPosition += doc.getTextWidth(word) + spaceBetweenWords; // Add space between words
      } else {
        xPosition += doc.getTextWidth(word); // No space after the last word in line
      }
    });
  }
}




const handleDownload = (appDataList, cmsPage) => {
  generatePDF(appDataList, cmsPage);
};

export default handleDownload;
