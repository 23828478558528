import React from "react";
import { URL_CONFIG } from "src/config/urlConfig";
import queryString from "query-string";

//DashBoard Api

export const fetchDashboardDataAPI = async (token: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/accounts/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch dashboard data");
    }
  } catch (error) {
    throw new Error("Error fetching dashboard data:", error.message);
  }
};

//UserDataTable Api

export const fetchUserDataList = async (
  searchQuery: any,
  limit?: number,
  pageNo?: number
) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: limit,
    pageNo: pageNo,
    status: "ACTIVE",
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};
//user id wise data

export const fetchUserDetailsId = async (userid: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/${userid}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};
//updateUserRegularNotation Api

export const updateUserRegularNotation = async (
  userId: any,
  regularNotation: any
) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/regular-notation`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: userId,
          regularNotation: regularNotation ? "regular" : "irregular",
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(
      "There was a problem with the fetch operation:",
      error.message
    );
  }
};

//AppDataTable Api

export const fetchApplicantsDataList = async (
  searchQuery: any,
  pageNo: any
) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "active",
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/applicants/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//MutualFunDataTable Api

export const fetchMutualFundsDataList = async (pageNo: any) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "active",
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/mutual-funds/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    const newDataList = data?.data || [];
    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//CmsPages Api

export const fetchCmsPagesData = async () => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/cms-pages/list`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//InquiryPage Details Api

export const fetchInquiryDataList = async (
  searchQuery: any,
  pageNo: number
) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "active",
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/inquiries/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//AccountDetails Api

export const fetchAccountDetails = async (adminId: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/accounts/details?adminUserId=${adminId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//ApplicantsDetails Api

export const fetchApplicantDetails = async (applicantId: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/applicants/details?applicantId=${applicantId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};
//Send Notification Api
export const sendNotification = async (
  token,
  userIds,
  notificationTitle,
  notificationBody,
  notificationType
) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/send-notification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          userIds: userIds,
          notificationTitle: notificationTitle,
          notificationBody: notificationBody,
          notificationType: notificationType,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data: " + error.message);
  }
};

//Notificationlist Api

export const fetchNotificationDetails = async (token, searchQuery, pageNo) => {
  const myObject = {
    limit: 10,
    pageNo: pageNo,
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/notification/list?${queryStringResult}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//patientList Api

export const fetchPatientList = async (token, searchQuery, pageNo) => {
  const myObject = {
    limit: 10,
    pageNo: pageNo,
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    let url = `${URL_CONFIG.BASE_URL}/admin/api/v1/users/patient/list?${queryStringResult}`;

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//id wise patientdata APi

export const fetchPatientDetails = async (token, id) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/patient-detail/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};


export const fetchVoluntaryData = async (token, searchQuery, pageNo) => {
  const myObject = {
    limit: 10,
    pageNo: pageNo,
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/vhp-amount/list?${queryStringResult}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//vhpaddTotalAmount APi

export const vhpAddTotalAmount = async (token, id, userIds, totalAmount) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/vhp-amount/add-total-amount?planId=${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          userIds: userIds,
          totalAmount: totalAmount,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data: " + error.message);
  }
};
//Update Amount api
export const vhpUpdateTotalAmount = async (token, vhpId, totalAmount) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/vhp-amount/update-total-amount`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          vhpId: vhpId,
          amount: totalAmount,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data: " + error.message);
  }
};

//amount delete api
export const vhpTotalAmountDelete = async (token, vhpId) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/vhp-amount/delete-vhp-amount/${vhpId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data: " + error.message);
  }
};

//volunteerUploadFile APi

export const uploadVounteerPdf = async (token, id, pdf, userIds) => {
  try {
    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("userId", JSON.stringify(userIds));
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/volunteer/upload-pdf?planId=${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to upload file");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error uploading file: " + error.message);
  }
};
//updatefile
export const vhpUpdateFile = async (token, pdf, volunteerId) => {
  try {
    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("volunteerId", volunteerId);

    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/volunteer/update-pdf`,
      {
        method: "PATCH",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data: " + error.message);
  }
};

// vounteerdatalist api

export const fetchVolunteerData = async (token, searchQuery, pageNo) => {
  const myObject = {
    limit: 10,
    pageNo: pageNo,
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/volunteer/list?${queryStringResult}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//vounteerDeatils Api

export const fetchVounteerDetails = async (token, id) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/volunteer/detail/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};
