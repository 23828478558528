import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { useUserDataContext } from "src/hooks/userDataContext";
import { Item, Item2 } from "./CustomeItem";

export const FamilyDetails = ({ familydetails }) => {
  const { familyApprovals, updateFamilyApprovals } = useUserDataContext();
  // const handleApprove = async (familyMemberId: any, documentType: string) => {
  //   updateFamilyApprovals((prevApprovals) => ({
  //     ...prevApprovals,
  //     [documentType]: true,
  //   }));

  //   await handleAction(familyMemberId, documentType, true, familydetails);
  // };

  // const handleReject = async (familyMemberId: any, documentType: string) => {
  //   updateFamilyApprovals((prevApprovals) => ({
  //     ...prevApprovals,
  //     [documentType]: false,
  //   }));

  //   await handleAction(familyMemberId, documentType, false, familydetails);
  // };

  return (
    <>
      <Grid xs={12} mt={3}>
        <Accordion
          style={{
            border: "1px solid rgb(0,0,0,0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreTwoToneIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h3" ml={1}>
              Family Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              xs={12}
              display={"flex"}
              height={"auto"}
              padding={2}
              spacing={2}
              justifyContent={"center"}
              flexWrap={"wrap"}
            >
              {familydetails?.familyMembers?.map((item, index) => (
                <>
                  <Grid item xs={6}>
                    <Item2 style={{ height: "32vh" }}>
                      <TableContainer>
                        <Box className="boxscroll">
                          <Typography variant="h3" ml={1} color={"white"}>
                            {item.relationType}
                          </Typography>
                        </Box>
                        <Table
                          sx={{
                            width: "10 rem",
                            "& tbody tr:last-child td": {
                              borderBottom: 0,
                            },
                          }}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="h4"
                                  color={"rgb(0,0,0,0.4)"}
                                >
                                  Name
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="h4"
                                  color={"rgb(0,0,0,0.7)"}
                                >
                                  {item.name || "-"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="h4"
                                  color={"rgb(0,0,0,0.4)"}
                                >
                                  Date Of Birth
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="h4"
                                  color={"rgb(0,0,0,0.7)"}
                                >
                                  {item?.birthdate
                                    ? new Date(
                                        item.birthdate
                                      ).toLocaleDateString()
                                    : "-"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Item key={index}>
                                  <Avatar
                                    alt="aadhar image"
                                    src="https://m.economictimes.com/thumb/msid-59910353,width-1200,height-900,resizemode-4,imgsize-30651/how-to-enrol-your-children-for-aadhaar"
                                  />
                                  <Typography
                                    variant="h6"
                                    marginLeft={1}
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <Typography fontWeight={900}>
                                      {" "}
                                      Aadhar Number
                                    </Typography>

                                    <Typography marginLeft={"120px"}>
                                      {item.aadharCardNumber || "-"}
                                    </Typography>
                                  </Typography>

                                  {/* <>
                                    {
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          {item.isAadharFrontApproved ? (
                                            <Box
                                              className='approvebox hoverEffect'
                                              sx={{ marginLeft: "10px" }}
                                            >
                                              <CheckCircleIcon
                                                onClick={() =>
                                                  handleApprove(
                                                    item.familyMemberId,
                                                    "isAadharFrontApproved"
                                                  )
                                                }
                                                style={{
                                                  color: "green",
                                                  width: "30px",
                                                  height: "30px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Box>
                                          ) : (
                                            <Box
                                              className='approvebox hoverEffect'
                                              sx={{ marginLeft: "10px" }}
                                            >
                                              <CheckCircleOutlineIcon
                                                onClick={() =>
                                                  handleApprove(
                                                    item.familyMemberId,
                                                    "isAadharFrontApproved"
                                                  )
                                                }
                                                style={{
                                                  color: "green",
                                                  width: "30px",
                                                  height: "30px",

                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Box>
                                          )}
                                          {item.isAadharFrontApproved ? (
                                            <Box className='approvebox hoverEffect'>
                                              <HighlightOffIcon
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  handleReject(
                                                    item.familyMemberId,
                                                    "isAadharFrontApproved"
                                                  )
                                                }
                                              />
                                            </Box>
                                          ) : (
                                            <Box className='approvebox hoverEffect'>
                                              <CancelIcon
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  handleReject(
                                                    item.familyMemberId,
                                                    "isAadharFrontApproved"
                                                  )
                                                }
                                              />
                                            </Box>
                                          )}
                                        </div>
                                      </>
                                    }
                                  </> */}
                                </Item>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Item2>
                  </Grid>
                </>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
